<template>
  <b-card>
    <div slot="header">
      {{ showTopBottom }} {{ showPerPage }} {{ title }} 
      <span style="float: right" v-show="source == 'spotify'">
        Bron: Spotify <i class="fa fa-spotify"></i>
      </span>
      <span style="float: right" v-show="subTitle">
        {{ subTitle }}
      </span>
      <b-spinner small v-show="trendsLoading" label="Spinning"></b-spinner>
    </div>
    
    <div v-show="countTotal == 0">
      No data available
    </div>

    <b-table striped small :fields="fields" :items="items" v-show="countTotal > 0">
      <template v-slot:head(title)="">
        {{ columnTitle }}
      </template>

      <template v-slot:cell(title)="row">
        <a v-if="canSelect == true" href="#" @click.prevent="$emit('item-click', {type: aggName, item: row.item})">{{ row.item.title }}</a>
        <span v-if="canSelect == false">{{ row.item.title }}</span>
      </template>

      <template v-slot:cell(count)="row">
        <span style="white-space: nowrap; font-family: 'Roboto Mono', monospace;">{{ row.item.count | numberFormat(absDecimals, numberLocale) }}</span>
      </template>

      <template v-slot:cell(percentage)="row">
        <span style="font-family: 'Roboto Mono', monospace;">{{ row.item.percentage | numberFormat }} %</span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
  import apiMixin from '@/mixins/RoyaltyApi'
  import axios from 'axios'
  
  export default {
    name: 'RoyaltyAnalyticsList',
    mixins: [apiMixin],
    data () {
      return {
        cancelToken: null,
        showPerPage: '',
        showTopBottom: 'Top',
        items: [],
        fields: [
        ], 
        trendsLoading: false,
        countTotal: null,
        absLabel: '',
        absDecimals: '',
      }
    },
    props: {
      watch: Number,
      title: String,
      columnTitle: {type: String, default: 'Title'},
      filter: Object,
      sortDirection: String,
      target: String,
      perPage: Number,
      aggName: String,
      canSelect: {type: Boolean, default: false},
      source: {type: String, default: ''},
      subTitle: {type: String, default: ''},
      showCount: {type: Boolean, default: true},
      numberLocale: String,
      titleLabels: {type: Object, default: function () { return {} } },
    },
    methods: {
      refreshTrends () {
        //if (this.trendsLoading) return;
        if (this.cancelToken) {
          this.cancelToken.cancel()
        }
        this.cancelToken = axios.CancelToken.source()

        this.items = []
        this.trendsLoading = true
        this.showPerPage = this.perPage

        if (this.sortDirection == 'asc')
          this.showTopBottom = 'Bottom'
        else
          this.showTopBottom = 'Top'

        this.fields = []

        if (this.filter.timerange == 'custom' && (
              this.filter.dateFrom == '' || this.filter.dateTo == '')) {
          return; 
        }

        this.$http
          .post('analytics/royalty-data', {
              filter: this.filter,
              aggName: this.aggName,
              target: this.target,
              perPage: this.perPage,
              sortDirection: this.sortDirection,
          }, { cancelToken: this.cancelToken.token })
          .then(response => {
            response = response.data
            this.countTotal = response.countTotal;
            this.absLabel = response.absLabel;
            this.absDecimals = response.absDecimals;
            this.items = []
            response.buckets.forEach((item) => {
              let title = item.title;
              if (this.titleLabels[item.key] !== undefined) {
                title = this.titleLabels[item.key] 
              }
              this.items.push({
                title: title,
                count: item.absolute,
                percentage: item.percentage,
                key: item.key,
              })
            })

            this.fields.push({ key: 'title' })
            this.fields.push({ key: 'percentage', class: 'text-right' })
            if (this.showCount) {
              this.fields.push({ key: 'count', label: this.absLabel, class: 'text-right' })
            }

          }).catch(() => {

          }).finally(() => {
            this.trendsLoading = false
          })
      },
    },
    watch: {
      watch: {
        handler() { this.refreshTrends() }, 
        deep: true
      },
    },
    mounted () {
      setTimeout(this.refreshTrends, 500);
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
